<template>
  <v-container fluid class="d-flex flex-column flex-nowrap pa-0" style="height: 100%; width: 100%">
    <v-card class="pa-2 secondary" tile dark>
      <v-row class="shrink" no-gutters>
        <v-col cols="6">
          <v-select
            v-model="selected"
            :items="reports"
            item-text="name"
            item-value="value"
            label="Tipo"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="6" class="text-right align-self-center">
          <v-btn class="primary" @click="openFilters" tile large>
            <v-icon>mdi-filter</v-icon>Filtrar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="grow" no-gutters v-if="!loading">
      <v-col cols="12">
        <logins-report v-if="selected === 'logins'" ref="logins"> </logins-report>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ApiService from '@/util/api.service';

export default {
  components: {
    'logins-report': () => import('@/components/usersReports/LoginsReports.vue'),
  },
  data() {
    return {
      loading: false,
      filters: {},
      selected: 'logins',
      cameras: [],
      groups: [],
      reports: [
        {
          name: 'Inicios de sesión',
          value: 'logins',
        },
      ],
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      // const camerasPromise = ApiService({
      //   url: '/cameras',
      //   method: 'get',
      // });
      // Promise.all([camerasPromise, groupsPromise]).then((values) => {
      //   this.cameras = values[0];
      //   this.groups = values[1];
      //   this.loading = false;
      // });
    },
    openFilters() {
      if (this.$refs[this.selected] && this.$refs[this.selected].filtrar) {
        this.$refs[this.selected].filtrar();
      }
    },
  },
};
</script>
